<template>
  <!-- 标题组 -->
  <div class="paddbox autobox">
    <el-card>
      <el-date-picker
        v-model="date"
        type="daterange"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="YYYY-MM-DD"
        :default-value="new Date()"
        value-format="YYYY-MM-DD"
        @change="dateChange"
      >
      </el-date-picker>
    </el-card>
    <el-card>
       <marquee
 direction="right"
 style="color:red;font-size:18px;"
 ><i class="el-icon-message-solid"></i
 >广告id左边有箭头，可以点击箭头查看广告创建详细信息</marquee>

      <div>
        <div>
          <!-- 选择器和按钮 查询账号 -->
        </div>
        <div>
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="id" label="广告id" width="260">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="560">
            </el-table-column>
            <el-table-column label="类型" sortable width="100">
              <template #default="scope">
                <span>{{
                  scope.row.type == 1
                    ? '广告组'
                    : scope.row.type == 2
                    ? '计划'
                    : '创意'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" sortable width="100">
              <template #default="scope">
                <div
                  :style="
                    scope.row.status == 0
                      ? 'color: rgb(103, 194, 58)'
                      : scope.row.status == 1
                      ? 'color: rgb(245, 108, 108)'
                      : 'color: rgb(144, 147, 153)'
                  "
                >
                  {{
                    scope.row.status == 0
                      ? '成功'
                      : scope.row.status == 1
                      ? '失败'
                      : '创建中'
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="accountId" label="返回信息" width="100">
              <template #default="scope">
                <el-button type="text" @click="chakan(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="accountId" label="查看结果集" width="100">
              <template #default="scope">
                <el-button type="text" @click="selectSonDataFun(scope.row)"
                  >查看结果集</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="accountId" label="账户id" width="180">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              sortable
              width="100"
            >
            </el-table-column>
            <el-table-column prop="nickName" label="投放" sortable width="70">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div>
        <el-drawer label="rtl" v-model="resultDataValue" size="25%">
          <el-card>
            <div class="liLists">
              <li class="Success">
                <span>计划创建成功：</span
                ><span>{{ resultData.planSuccess }}</span>
              </li>
              <li class="Error">
                <span>计划创建失败：</span
                ><span>{{ resultData.planError }}</span>
              </li>
              <li class="Success">
                <span>创意创建成功：</span
                ><span>{{ resultData.cySuccess }}</span>
              </li>
              <li class="Error">
                <span>创意创建失败：</span><span>{{ resultData.cyError }}</span>
              </li>
            </div></el-card
          >
        </el-drawer>
      </div>
      <div>
        <el-drawer label="rtl" v-model="drawerValue" size="50%">
          <el-card>
            <div>
              {{ planMsg }}
            </div></el-card
          >
        </el-drawer>
      </div>
      <div class="pagination">
        <pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :total="total"
          :pagesize="pagesize"
          :currentPage="currentPage"
        ></pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
// 分页组件
import pagination from '@/components/pagination.vue'
import {
  selectToutiaoPlanResult,
  selectSonData,
} from '@/request/new/videoBatchManagement'

import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch, defineComponent } from 'vue'
import { login } from '@/request'

export default {
  name: 'toutiaoModelInfo',
  components: { pagination, ElMessage },
  setup() {
    const state = reactive({
      // 日期
      date: [],
      // 表格数据
      tableData: [],
      // 抽屉的值
      drawerValue: false,
      // 结果集数据抽屉的值
      resultDataValue: false,
      // 结果集数据
      resultData: '',
      // 选中的消息内容
      planMsg: '',
      // 选中的账户id
      accountId: '',
      // 条数
      pagesize: 20,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
      // 分页开始
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        selectToutiaoPlanResultFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        selectToutiaoPlanResultFun()
      },
      // 查看详情
      chakan: (val) => {
        state.drawerValue = true
        state.planMsg = eval("'" + val.result + "'")
      },
      // 查看结果集
      selectSonDataFun: (val) => {
        const datas = {
          id: val.id,
        }
        selectSonData(datas).then((res) => {
          state.resultData = res.data
          state.resultDataValue = true
        })
      },
      //日期改变
      dateChange: () => {
        selectToutiaoPlanResultFun()
      },
    })

    // 默认日期
    const setCurrentDate = () => {
      const s = new Date().getTime()
      const date = new Date(s)
      // 年
      const year = date.getFullYear()
      // 月
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      // 日
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

      state.date = [
        year + '-' + month + '-' + day,
        year + '-' + month + '-' + day,
      ]

      console.log(
        '%c [ state.date ]-1679',
        'font-size:13px; background:pink; color:#bf2c9f;',
        state.date
      )
    }
    setCurrentDate()

    const selectToutiaoPlanResultFun = () => {
      const datas = {
        startDate: state.date[0],
        endDate: state.date[1],
        currentPage: state.currentPage,
        size: state.pagesize,
      }
      if (state.accountId != '') {
        datas.accountId = state.accountId
      }
      selectToutiaoPlanResult(datas).then((res) => {
        state.tableData = res.data.data
        state.currentPage = res.data.currenPage
        state.total = res.data.totalSize
      })
    }

    selectToutiaoPlanResultFun()

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      selectToutiaoPlanResultFun,
      setCurrentDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.liLists {
  > li {
    margin-bottom: 10px;
  }
}

.Success {
  color: rgb(103, 194, 58);
}

.Error {
  color: rgb(245, 108, 108);
}
</style>
